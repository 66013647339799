import {twMerge} from '@/stylesheets/twMerge';
import CardGrid from '@/components/base/layouts/CardGrid/CardGrid';
import type {Mode} from '@/components/base/types';
import type {ImageProps} from '@/components/base/elements/Image/Image';

import CardMetric from './CardMetric';

const CARD_TYPES = ['default', 'outline', 'shadow', 'topDivider'] as const;

interface CardStatsProps {
  stats: {
    value: string;
    label: string;
    image?: ImageProps;
  }[];
  className?: string;
  cardClassName?: string;
  labelClassName?: string;
  oneColumn?: boolean;
  type?: (typeof CARD_TYPES)[number];
  mode?: Mode;
  withContainer?: boolean;
  ratio?: number;
}

export default function CardStats({
  stats,
  className,
  cardClassName,
  labelClassName,
  oneColumn = false,
  type = 'outline',
  mode = 'light',
  withContainer = true,
  ratio,
}: CardStatsProps) {
  const numberOfColumns = oneColumn
    ? 1
    : stats.length === 1
      ? 1
      : stats.length === 2
        ? 2
        : stats.length === 3
          ? 3
          : 4;

  const maxLength = Math.max(...stats.map((stat) => stat.value.length));

  return (
    <CardGrid
      numberOfColumns={numberOfColumns}
      withContainer={withContainer}
      className={twMerge('xs:grid-cols-1 gap-y-10', className)}
    >
      {stats.map((stat, index) => (
        <CardMetric
          key={index}
          type={type}
          mode={mode}
          stat={stat}
          ratio={ratio}
          maxLength={maxLength}
          className={cardClassName}
          labelClassName={labelClassName}
        />
      ))}
    </CardGrid>
  );
}
