import {cva} from 'class-variance-authority';

import Image, {type ImageProps} from '@/components/base/elements/Image/Image';
import {imageStyles} from '@/components/base/modules/Card/styles';
import {twMerge} from '@/stylesheets/twMerge';
import Typography from '@/components/base/elements/Typography/Typography';
import {useSiteData} from '@/hooks/useSiteData';

import {wrapperStyles} from './styles';

interface CardMetricProps {
  stat: {
    value: string;
    label: string;
    image?: ImageProps;
  };
  type?: 'default' | 'outline' | 'shadow' | 'topDivider';
  mode?: 'light' | 'dark';
  ratio?: number;
  maxLength?: number;
  className?: string;
  labelClassName?: string;
}

const valueFont = 'font-shopifysans font-bold';
const baseSize = 80; // base font size
/**
 * Expected average character width. Depends on the font and locale.
 * Used to decrease the base font size in case one of the metrics in a series is too long to fit in.
 * This can't be an exact value and should be fine-tuned to the longest actual use cases.
 * The safest option would be to set this to the widest letter in the locale, e.g. 'M' in English
 * (66px for font-shopifysans and a baseSize of 80px). But this would lead to overcorrection in most cases,
 * making the longest strings only take up about 2/3 of the available width.
 * If you face a case of a line that doesn't fit in - increase the value for the relevant locale until it does.
 * Avoid decreasing the values, unless visually re-checking all the existing usages.
 */
const charWidths: {[key: string]: number} = {
  default: 45,
  ja: 75,
  'zh-CN': 68,
};

const valueStyles = cva(valueFont, {
  variants: {
    mode: {
      light: 'text-metrics-light fill-metrics-light',
      dark: 'text-white fill-white',
    },
  },
});

export default function CardMetric({
  stat,
  type,
  mode,
  ratio = 0.25,
  maxLength = 0,
  className,
  labelClassName,
}: CardMetricProps) {
  const dividerBorderStyle =
    type === 'topDivider' ? 'rounded-none border-white border-opacity-20' : '';
  const {value, label, image} = stat;

  const {site} = useSiteData();
  const charWidth = charWidths[site.locale] ?? charWidths.default;
  const height = 100;
  const width = height / ratio;
  const correctedSize = Math.round(
    Math.min(baseSize, baseSize * (width / (maxLength * charWidth))),
  );
  const fontSize = `${correctedSize}px`;
  const yOffset = Math.round(height * 0.8 - (baseSize - correctedSize) / 2);

  return (
    <div
      className={twMerge(
        wrapperStyles({horizontal: true, mode, type}),
        'rounded-lg',
        dividerBorderStyle,
        className,
      )}
    >
      <div className="flex">
        {image && (
          <div>
            <Image
              {...image}
              className={twMerge(
                imageStyles({horizontal: true}),
                'w-12 mr-2',
                image.className,
              )}
            />
          </div>
        )}
        <div className="z-10 flex-grow">
          <svg viewBox={`0 0 ${width} ${height}`}>
            <text
              style={{fontSize}}
              className={valueStyles({mode})}
              y={yOffset}
              x="0"
            >
              {value}
            </text>
          </svg>
          <Typography
            mode={mode}
            className={twMerge('text-lg leading-[26px] mt-2', labelClassName)}
          >
            {label}
          </Typography>
        </div>
      </div>
    </div>
  );
}
